// @flow
import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import backStyles from '../css/back.module.scss';

const Back = (props) => (
	<section className="separator separator--top separator--thick type--center">
		<TransitionLink to="/" className={backStyles.back}>Back</TransitionLink>
	</section>
);

export default Back;
