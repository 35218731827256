// @flow
import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Back from '../components/Back';
import '../css/list.scss';

const Projekte = (props) => {
	const data = useStaticQuery(graphql`
		query ProjectsQuery {
			allProject {
				nodes {
					feature
					slideshow
					name
					location
					slug
				}
			}
		}
	`);

	const featured = data.allProject.nodes.filter(project => project.feature);
	const nonFeatured = data.allProject.nodes.filter(project => !project.feature);

	return (
		<Layout className={props.transitionStatus}>
			<SEO title="Unsere Projekte" theme="dark" />
			<h1>Unsere Projekte</h1>
			<ul className="list separator separator--top separator--spacer type--center">
				{featured.filter(project => !project.slideshow).map(project => (
					<li key={project.slug} className="list__item separator separator--bottom">
						<Link to={`/projekte/${project.slug}`}>
							{project.name}<br />{project.location}
						</Link>
					</li>
				))}
			</ul>
			<ul className="separator separator--block separator--spacer list type--center">
				{nonFeatured.map(project => (
					<li key={project.slug} className="list__item list__item--small">
						{project.name}<br />{project.location}
					</li>
				))}
			</ul>
			<Back />
		</Layout>
	);
};

export default Projekte;
